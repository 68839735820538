import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { motion } from "framer-motion";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Button } from "@material-ui/core";
import { TwitchEmbed } from "react-twitch-embed";

const variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const Reveal = ({ children, className, duration, offset }) => {
  return (
    <motion.div
      className={className}
      variants={variants}
      initial="hidden"
      animate="visible"
      transition={{
        duration: duration,
        delay: offset,
      }}
    >
      {children}
    </motion.div>
  );
};
Reveal.defaultProps = {
  className: "",
  duration: 0.25,
  offset: 0,
};

const Index = () => {
  return (
    <Layout classNames="pt-20 flex flex-col content-center">
      <SEO title="Welcome" />
      <div className="mx-5 md:mx-30 lg:mx-80 text-gray-900">
        <Reveal>
          <img src="/logo.svg" alt="logo" className="h-30" />
        </Reveal>
        <Reveal offset="0.25">
          <h1 className="text-3xl font-bold ">
            The common room that accepts you.
          </h1>
          <p className="font-light text-lg">
            The beta to my shitzu. <br />
            The rabbit hole you didn't mean to fall in to. <br />
          </p>
        </Reveal>

        <Reveal className="my-5 flex flex-col md:flex-row" offset="0.35">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FaInstagram />}
            href="https://www.instagram.com/natmegy/"
          >
            Instagram
          </Button>
          <Button
            className="mt-2 md:mt-0 md:ml-2"
            variant="contained"
            color="primary"
            startIcon={<FaYoutube />}
            href="#"
          >
            Youtube
          </Button>
          <Button
            className="mt-2 md:mt-0 md:ml-2"
            variant="contained"
            color="primary"
            startIcon={<FaTwitter />}
            href="https://twitter.com/_natmeg"
          >
            Twitter
          </Button>
        </Reveal>
        <Reveal offset="0.4" duration="0">
          <TwitchEmbed width="100%" channel="natmegy" muted={true} />
        </Reveal>
      </div>
    </Layout>
  );
};

export default Index;
